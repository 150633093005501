import React, { createContext, useCallback, useState } from 'react';
import Confirmation, { ConfirmationProps } from 'shared/confirmation';

export type ConfirmationOpen = (config: ConfirmationProps) => void;
type ConfirmationContext = Readonly<[ConfirmationOpen]>;

const initialContext: ConfirmationContext = [() => undefined];
const ConfirmationContext = createContext<ConfirmationContext>(initialContext);

const ConfirmationProvider: React.FC = (props) => {
  const [state, setState] = useState<ConfirmationProps & { isOpen: boolean }>({
    isOpen: false,
    title: '',
    content: '',
  });
  const openConfirmation = useCallback((config: ConfirmationProps) => {
    setState({ isOpen: true, ...config });
  }, []);
  const contextValue = [openConfirmation] as const;

  const { isOpen, onConfirm, onCancel, ...confirmationProps } = state;

  const internalOnConfirm = () => {
    setState({ isOpen: false, title: '', content: '' });
    if (onConfirm) {
      onConfirm();
    }
  };

  const internalOnCancel = () => {
    setState({ isOpen: false, title: '', content: '' });
    if (onCancel) {
      onCancel();
    }
  };

  const { children } = props;
  return (
    <ConfirmationContext.Provider value={contextValue}>
      {children}
      {isOpen && (
        <Confirmation
          onConfirm={internalOnConfirm}
          onCancel={internalOnCancel}
          {...confirmationProps}
        />
      )}
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationContext };
export default ConfirmationProvider;
