// / <reference types="@types/segment-analytics" />

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

const { REACT_APP_ENV, REACT_APP_SEGMENT_KEY } = process.env;

export const load = () => {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'beta') {
    if (window && window?.analytics) {
      window?.analytics?.load(REACT_APP_SEGMENT_KEY!);
    }
  }
};
export const page = (p: string, properties: any = {}) => {
  if (window && window.analytics) {
    window?.analytics?.page(p, properties, {});
  }
};

export const track = (name: string, properties: any = {}) => {
  if (window && window.analytics) {
    window?.analytics?.track(name, properties, {});
  }
};

export const identify = (userId: string, properties: any = {}) => {
  if (window && window.analytics) {
    window?.analytics?.identify(userId, properties, {});
  }
};

export const group = (groupId: string, properties: any = {}) => {
  if (window && window.analytics) {
    window?.analytics?.group(groupId, properties, {});
  }
};
