import { bodyRegister } from 'components/authenticate/components/register/registerForm/register.types';
import { isEmpty } from 'lodash';
import { User } from 'types/user.types';
import endpoint from '../config';

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                 */
/* -------------------------------------------------------------------------- */

const env = process.env.REACT_APP_ENV;
const csrfTokenLocalStorageKey = `vitau-panel-csrfToken-${env}`;
const CSRF_TOKEN_HEADER_KEY = 'x-csrftoken';

/* -------------------------------------------------------------------------- */
/*                               CLIENT SERVICE                               */
/* -------------------------------------------------------------------------- */

type ClientExtraParams = {
  body?: Record<string, any>;
  customHeaders?: HeadersInit;
};

/**
 * AuthClient used to execute auth related requests.
 * @param path - /path/to/endpoint
 * @param options - body, customHeaders
 * @returns API response as json or throws
 *
 * ? Future work: Integrate with apollo somehow.
 */

async function client<ClientResponse extends any = any>(
  path: string,
  { body = {}, customHeaders = {} }: ClientExtraParams = {}
) {
  const headers: HeadersInit = {
    'Content-Type': !isEmpty(body) ? 'application/json' : '',
    'X-Api-Key': `${endpoint.API_TOKEN}`,
    ...customHeaders,
  };

  const config: RequestInit = {
    method: !isEmpty(body) ? 'POST' : 'GET',
    credentials: 'include',
    body: !isEmpty(body) ? JSON.stringify(body) : undefined,
    headers,
  };

  return fetch(`${endpoint.API_URL}${path}`, config).then(async (response) => {
    const data = (await response.json().catch(() => ({}))) as ClientResponse;
    if (response.ok) {
      return data;
    }
    return Promise.reject(data);
  });
}

/* -------------------------------------------------------------------------- */
/*                                 CSRF TOKEN                                 */
/* -------------------------------------------------------------------------- */

type CsrfTokenResponse = {
  csrftoken: string;
};

/**
 * Requests csrf token cookie and local csrf token
 * intended to be sent in every request header
 * @returns a promise with CsrfTokenResponse
 */
const getCsrfToken = () => {
  return client<CsrfTokenResponse>('/set-csrf-token/');
};

const saveCsrfToken = (token: string) => {
  localStorage.setItem(csrfTokenLocalStorageKey, token);
};

const removeCsrfToken = () => {
  localStorage.removeItem(csrfTokenLocalStorageKey);
};

/**
 * Returns the stored csrfToken if it exists. If it doesn't it
 * requests a new csrfToken.
 * @returns csrfToken
 */
const externalGetCsrfToken = async () => {
  const storedCsrfToken = localStorage.getItem(csrfTokenLocalStorageKey);
  if (storedCsrfToken) {
    return storedCsrfToken;
  }

  const newCsrfToken = await getCsrfToken();
  saveCsrfToken(newCsrfToken.csrftoken);
  return newCsrfToken.csrftoken;
};

const refreshCsrfToken = async () => {
  removeCsrfToken();
  return externalGetCsrfToken();
};

/* -------------------------------------------------------------------------- */
/*                                   WHOAMI                                   */
/* -------------------------------------------------------------------------- */

/**
 * Get current logged in user
 * @returns user
 */
const whoAmI = () => {
  return client<User>('/whoami/');
};

/* -------------------------------------------------------------------------- */
/*                               SESSION COOKIE                               */
/* -------------------------------------------------------------------------- */

type CredentialsInfo = {
  email: string;
  password: string;
};

/**
 * Requests a session cookie by sending the user credentials
 * and the required csrf token
 * @param credentials
 * @param csrf
 * @returns
 */
const setSessionCookie = (
  credentials: CredentialsInfo,
  csrf: CsrfTokenResponse
) => {
  const sessionHeaders = {
    [CSRF_TOKEN_HEADER_KEY]: csrf.csrftoken,
  };
  return client<CsrfTokenResponse>('/session-login/', {
    body: credentials,
    customHeaders: sessionHeaders,
  });
};

/* -------------------------------------------------------------------------- */
/*                                    LOGIN                                   */
/* -------------------------------------------------------------------------- */

/**
 * Log in a user. This function also calls setCSRFToken before logging in
 * and calls whoAmI after logging in
 * @param email
 * @param password
 * @returns Promise(User) - The logged in user
 */
const login = async (credentials: CredentialsInfo) => {
  const crsfResponse = await getCsrfToken();
  const sessionCsrfToken = await setSessionCookie(credentials, crsfResponse);
  saveCsrfToken(sessionCsrfToken.csrftoken);
  return whoAmI();
};

/* -------------------------------------------------------------------------- */
/*                                   LOGOUT                                   */
/* -------------------------------------------------------------------------- */

/**
 * Removes cookies from the API and deletes local csrftoken
 */
const logout = () => {
  removeCsrfToken();
  return client('/session-logout/');
};

/* -------------------------------------------------------------------------- */
/*                                 CHECK AUTH                                 */
/* -------------------------------------------------------------------------- */

type CheckAuthResponse = {
  isAuthenticated: boolean;
};

/**
 * Checks the current auth state. This call to the API always returns
 * a boolean.
 * @returns Promise (Boolean) - Current auth state
 *    - true : A user is logged in
 *    - false: No user is logged in
 */
const checkAuth = async (): Promise<Boolean> => {
  const session = await client<CheckAuthResponse>('/session/');
  return session.isAuthenticated;
};

/* -------------------------------------------------------------------------- */
/*                                   REGISTER                                 */
/* -------------------------------------------------------------------------- */

/**
 * Register a doctor
 */
const register = async (data: bodyRegister) => {
  return client('/users/register/', {
    body: data,
  });
};

/* -------------------------------------------------------------------------- */
/*                                   EXPORTS                                  */
/* -------------------------------------------------------------------------- */

// Export following the module pattern
export default {
  login,
  logout,
  register,
  checkAuth,
  whoAmI,
  getCsrfToken: externalGetCsrfToken,
  refreshCsrfToken,
  constants: {
    CSRF_TOKEN_HEADER_KEY,
  },
};
