import { gql } from '@apollo/client';

export const STORES_GET = gql`
  query stores {
    stores @rest(type: "[Store]", path: "/stores/") {
      id
      created_at
      updated_at
      name
      company_name
      city
      state
      zipcode
      street
      exterior_number
      phone
      neighborhood
    }
  }
`;
