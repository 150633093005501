import { useEffect } from 'react';

// const useOutsideClick = (
//   ref:
//     | React.MutableRefObject<undefined>
//     | React.MutableRefObject<null>
//     | React.RefObject<HTMLElement>,
//   callback: () => void
// ) => {
//   const handleClick = (e: MouseEvent) => {
//     // @ts-ignore
//     if (ref.current && !ref.current.contains(e.target)) {
//       callback();
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('click', handleClick);

//     return () => {
//       document.removeEventListener('click', handleClick);
//     };
//   });
// };

function useOutsideClick(
  ref:
    | React.MutableRefObject<undefined>
    | React.MutableRefObject<null>
    | React.RefObject<HTMLElement>,
  handler: () => void
) {
  useEffect(
    () => {
      const listener = (event: MouseEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        // @ts-ignore
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler();
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export default useOutsideClick;
