import ROLES, { TRole } from 'constants/roles';

const { REACT_APP_ENV } = process.env;
type ICheckRole = (value: number | number[], role: number) => boolean;

// ID users for Roberto de Clivi
const CLIVI_USERS_ID = [20465, 20466, 20467, 20469, 20471, 22574, 2053];
// ID users for Okani
const OKANI_USERS_ID = [22101, 798, 1491];
const SOFIASALUD_USERS_ID = [
  1264, 2611, 26264, 26266, 26267, 26268, 26269, 26270, 26271, 26272, 26273,
  26274, 26275, 26276,
];
const NOMADRX_USERS_ID = [2700, 40199, 40200, 45618];

const ALLIES_IDS = [
  ...CLIVI_USERS_ID,
  ...OKANI_USERS_ID,
  ...SOFIASALUD_USERS_ID,
  ...NOMADRX_USERS_ID,
];

const checkRole: ICheckRole = (value, role) => {
  if (typeof value === 'number') return value === role;
  return value.includes(role);
};

export const isAdmin = (value: number | number[]) =>
  checkRole(value, ROLES.admin);

export const isPatient = (value: number | number[]) =>
  checkRole(value, ROLES.patient);

export const isDoctor = (value: number | number[]) =>
  checkRole(value, ROLES.doctor);

export const isOperatorAlly = (value: number | number[]) =>
  checkRole(value, ROLES.employee);

export const isInsurer = (value: number | number[]) =>
  checkRole(value, ROLES.insurer);

export const isBroker = (value: number | number[]) =>
  checkRole(value, ROLES.broker);

// export const isOperatorAlly = (value: number) => {
//   // return checkRole(value, ROLES.operatorAlly);
//   return [826, 2069, 952].includes(value);
// };

export const isPanelUser = (value: number | number[]) => {
  return (
    isAdmin(value) ||
    isDoctor(value) ||
    isInsurer(value) ||
    isBroker(value) ||
    isOperatorAlly(value)
  );
};

export const isUserAlly = (value: number) => {
  if (ALLIES_IDS.includes(value)) return true;
  return false;
};

export const getFirstRole: (value: number | number[]) => TRole | string = (
  value
) => {
  if (isAdmin(value)) return 'admin';
  if (isDoctor(value)) return 'doctor';
  if (isInsurer(value)) return 'insurer';
  if (isBroker(value)) return 'broker';
  return '';
};

const ADMIN_NOTIFICATIONS = [25120, 969, 365, 1925, 17601, 65324, 6967];
const ADMIN_NOTIFICATIONS_DEV = [2931, 952, 955, 900, 83, 2931];

export const ADMIN_NOTIFICATIONS_IDS =
  REACT_APP_ENV === 'production' || REACT_APP_ENV === 'beta'
    ? ADMIN_NOTIFICATIONS
    : ADMIN_NOTIFICATIONS_DEV;
