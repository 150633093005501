import { gql } from '@apollo/client';

export const SUPPLIERS_GET = gql`
  query suppliers {
    suppliers @rest(type: "[Supplier]", path: "/suppliers/") {
      id
      supplier_products
      created_at
      updated_at
      deleted_at
      name
      products
    }
  }
`;

/*
export const SUPPLIERS_GET_PAGINATED = gql`
  query suppliersPaginated(
    $limit: String
    $page: String
    $search: String
    $ordering: String
  ) {
    suppliersPaginated(
      limit: $limit
      page: $page
      search: $search
      ordering: $ordering
    )
      @rest(type: "SuppliersPayload", path: "/proveedores/?{args}")
      @connection(key: "suppliersPaginated") {
      count
      next
      previous
      results {
        id
        created_at
        updated_at
        name
      }
    }
  }
`;
*/

export const SUPPLIER_GET = gql`
  query supplier($id: String!) {
    supplier(id: $id) @rest(type: "Supplier", path: "/suppliers/{args.id}") {
      id
      created_at
      updated_at
      name
      supplier_products
    }
  }
`;
