import { useQuery } from '@apollo/client';
import { useToast } from 'hooks';
import { Supplier } from 'types/supplier.types';
import { SUPPLIERS_GET } from './suppliers.queries';

const useSuppliers = () => {
  const [openToast] = useToast();
  return useQuery<{ suppliers: Supplier[] }>(SUPPLIERS_GET, {
    fetchPolicy: 'no-cache',
    onError: () =>
      openToast('Error al cargar distribuidores.', { type: 'error' }),
  });
};

export default useSuppliers;
