import React, { Suspense, useEffect, useState } from 'react';
import { lazyWithRetry } from 'utils/lazyWithRetry';
import { load } from './analytics';
import useAuth from './hooks/useAuth';
import SplashScreen from './components/splashScreen/SplashScreen';

const Main = lazyWithRetry(async () => {
  const [module] = await Promise.all([
    import(
      /* webpackChunkName: "Main", webpackPrefetch: true */ './components/Main'
    ),
    // Set timeout is used to avoid a flash of splashscreen
    new Promise((resolve) => setTimeout(resolve, 100)),
  ]);
  return module;
});

const Authenticate = lazyWithRetry(async () => {
  const [module] = await Promise.all([
    import(/* webpackChunkName: "Authenticate" */ './components/authenticate'),
    // Set timeout is used to avoid a flash of splashscreen
    new Promise((resolve) => setTimeout(resolve, 100)),
  ]);
  return module;
});

const App: React.FC = () => {
  const [authState] = useAuth();

  const [userType, setUserType] = useState('otro');

  const { authentication } = authState;

  // TO-DO
  // Add user type validation

  useEffect(() => {
    load();
    setUserType('vitau');
  }, []);

  if (authentication === 'idle') {
    return <SplashScreen />;
  }

  if (userType === 'vitau') {
    import('./vitau_tokens.css');
  }
  if (userType === 'other') {
    import('./other_tokens.css');
  }

  return (
    <Suspense fallback={<SplashScreen />}>
      {authentication === 'authenticated' ? <Main /> : <Authenticate />}
    </Suspense>
  );
};

export default App;
