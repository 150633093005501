const getConfig = () => {
  return {
    ENV: `${process.env.REACT_APP_ENV}`,
    API_URL: `${process.env.REACT_APP_API_URL}`,
    ES_URL: `${process.env.REACT_APP_ES_URL}`,
    ES_CREDENTIALS: `${process.env.REACT_APP_ES_CREDENTIALS}`,
    // VITAU_URL: `${process.env.REACT_APP_REACT_APP_VITAU_URL}`,
    METABASE_SITE_URL: `${process.env.REACT_APP_METABASE_SITE_URL}`,
    METABASE_SECRET_KEY: `${process.env.REACT_APP_METABASE_SECRET_KEY}`,
    API_TOKEN: `${process.env.REACT_APP_API_TOKEN}`,
    API_KEY_WEB: `${process.env.REACT_APP_APY_KEY_WEB}`,
  };
};

export default getConfig();
