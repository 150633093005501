import { colors } from '../types/framework.types';

export type TRole =
  | 'admin'
  | 'patient'
  | 'doctor'
  | 'insurer'
  | 'broker'
  | 'guest'
  | 'employee';

type TRoleTranslation =
  | 'admin'
  | 'paciente'
  | 'doctor'
  | 'aseguradora'
  | 'broker'
  | 'guest'
  | 'aliado operador';

type TRoleInfo = {
  role: TRole;
  text: TRoleTranslation;
  color: colors;
};

const ROLES: Record<TRole, number> = {
  admin: 1,
  patient: 2,
  doctor: 3,
  insurer: 4,
  broker: 5,
  guest: 6,
  employee: 8,
};

export const ROLES_INFO: Record<number, TRoleInfo> = {
  1: {
    role: 'admin',
    text: 'admin',
    color: 'softBlue',
  },
  2: {
    role: 'patient',
    text: 'paciente',
    color: 'softCyan',
  },
  3: {
    role: 'doctor',
    text: 'doctor',
    color: 'softOrange',
  },
  4: {
    role: 'insurer',
    text: 'aseguradora',
    color: 'softPurple',
  },
  5: {
    role: 'broker',
    text: 'broker',
    color: 'softYellow',
  },
  6: {
    role: 'guest',
    text: 'guest',
    color: 'softYellow',
  },
  8: {
    role: 'employee',
    text: 'aliado operador',
    color: 'softPurple',
  },
};

export default ROLES;
