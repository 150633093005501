import React from 'react';
import { ButtonPrimary } from '@vitau/button';
import brokenPageIllustration from 'assets/images/broken-page.svg';
import './brokenScreen.scss';

const BrokenScreen: React.FC = () => {
  return (
    <section className="wrapper errorPage errorPage--background">
      <main className="errorPage-main">
        <div className="errorPage-info">
          <h1 className="title title--display">¡Ups! &#128517;</h1>
          <p className="subhead">
            Lo sentimos, algo ha ocurrido mal y estamos trabajando en ello,
            actualiza la página o vuelve más tarde.
          </p>
          <ButtonPrimary
            onClick={() => {
              window.location.reload();
            }}
          >
            Actualizar la página
          </ButtonPrimary>
        </div>
        <div className="errorPage-image">
          <img
            src={brokenPageIllustration}
            alt="ilustración de personas tratando de solucionar el error"
          />
        </div>
      </main>
    </section>
  );
};

export default BrokenScreen;
