import React, { useEffect, useState } from 'react';
import { ButtonTertiary, ButtonPrimary } from '@vitau/button';
import Backdrop from 'shared/backdrop';
import './confirmation.scss';

type ConfirmationType = 'info' | 'success' | 'warning' | 'destructive';
export type ConfirmationProps = {
  type?: ConfirmationType;
  title: string;
  content: JSX.Element | string;
  textConfirmButton?: string;
  textCancelButton?: string;
  icon?: string;
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

const TypesData: Record<
  ConfirmationType,
  { textButton: string; icon: string }
> = {
  info: {
    textButton: 'Entendido',
    icon: 'fas fa-info-circle',
  },
  success: {
    textButton: 'Confirmar',
    icon: 'fas fa-check-circle',
  },
  warning: {
    textButton: 'Seguir',
    icon: 'fas fa-exclamation-triangle',
  },
  destructive: {
    textButton: 'Borrar',
    icon: 'fas fa-times-circle',
  },
};

type ConfirmationStates = 'hidden' | 'displaying';

const Confirmation: React.FC<ConfirmationProps> = ({
  type = 'destructive',
  title,
  content,
  textConfirmButton = TypesData[type].textButton,
  textCancelButton = 'Cancelar',
  icon = TypesData[type].icon,
  className = '',
  onConfirm,
  onCancel,
}) => {
  const [state, setState] = useState<ConfirmationStates>('hidden');

  useEffect(() => {
    setTimeout(() => {
      setState('displaying');
    }, 0);
  }, []);

  const innerOnCancel = () => {
    setState('hidden');
    setTimeout(() => {
      if (onCancel) {
        onCancel();
      }
    }, 200);
  };

  const innerOnConfirm = () => {
    setState('hidden');
    setTimeout(() => {
      if (onConfirm) {
        onConfirm();
      }
    }, 200);
  };

  return (
    <Backdrop state={state}>
      <div
        className={`confirmation confirmation--${type} confirmation--${state} ${className}`}
      >
        <i className={icon} />
        <div>
          <h1>{title}</h1>
          {typeof content === 'string' ? <p>{content}</p> : content}
          <div className="confirmation-button-container">
            <ButtonTertiary onClick={innerOnCancel}>
              {textCancelButton}
            </ButtonTertiary>
            <ButtonPrimary onClick={innerOnConfirm}>
              {textConfirmButton}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default Confirmation;
