import { Supplier, SupplierType } from 'types/supplier.types';

interface FixSupplierOptionsParams {
  suppliersData: { suppliers: Supplier[] } | undefined
}

export type supplierOptionType = {
  value: number;
  label: string;
}

const fixSupplierOptions = ({ suppliersData }: FixSupplierOptionsParams) => {

  if (suppliersData) {
    const suppliersOptions: supplierOptionType[] = suppliersData.suppliers.map((sup) => {
      return { value: sup.id, label: sup.name }
    })

    return { suppliersOptions }
  }

  return { suppliersOptions: [{ value: 0, label: '' }] } as { suppliersOptions: supplierOptionType[] }
}


const fixInitialSuppliers = ({ suppliersData }: FixSupplierOptionsParams) => {

  if (suppliersData) {
    const suppliersInitialState: SupplierType[] = suppliersData.suppliers.map((supplier) => {
      return {
        id: supplier.id,
        name: supplier.name,
        total: '',
        availableCredit: '0',
        totalUnits: 0,
        details: [],
      }
    })

    return { suppliersInitialState }
  }

  return {
    suppliersInitialState: [{
      id: 0,
      name: '',
      total: '',
      availableCredit: '0',
      totalUnits: 0,
      details: [],
    }]
  } as { suppliersInitialState: SupplierType[] }
}

export { fixSupplierOptions, fixInitialSuppliers };
