import { useQuery } from '@apollo/client';
import { useToast } from 'hooks';
import { Supplier } from 'types/supplier.types';
import { SUPPLIER_GET } from './suppliers.queries';

const useSupplier = (id?: string) => {
  const [openToast] = useToast();

  return useQuery<{ supplier: Supplier }>(SUPPLIER_GET, {
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
    onError: () => {
      openToast('Error al cargar el proveedor.', { type: 'error' });
    },
    skip: !id,
  });
};

export default useSupplier;
