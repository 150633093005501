import { User } from 'types/user.types';

const constructName = (user?: User, fullname = false) => {
  if (!user) return '';
  const { first_name, last_name } = user;
  const second_last_name =
    fullname && !!user.second_last_name ? ` ${user.second_last_name}` : '';
  return `${first_name} ${last_name} ${second_last_name}`;
};

export default constructName;
