import {
  page as analyticsPage,
  track as analyticsTrack,
  identify as analyticsIdentify,
  group as analyticsGroup,
} from 'analytics';
import { User } from 'types/user.types';
import { getFirstRole } from 'utils/checkRole';
import useAuth from './useAuth';

export const useAnalytics = () => {
  const [{ user }] = useAuth();

  const mapTrackingProperties = (user?: User, properties?: any) => ({
    user_type: getFirstRole(user?.groups ?? []),
    email: user?.email,
    ...properties,
  });

  const identify = (userId: string) => {
    analyticsIdentify(userId, mapTrackingProperties(user));
  };

  const group = (groupId: string) => {
    analyticsGroup(groupId, mapTrackingProperties(user));
  };

  const page = (p: string) => {
    analyticsPage(p, mapTrackingProperties(user));
  };

  const track = (name: string, properties?: any) => {
    analyticsTrack(name, mapTrackingProperties(user, properties));
  };

  return { identify, group, page, track };
};

export default useAnalytics;
