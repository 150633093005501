/* eslint-disable no-debugger */
import React from 'react';

type ColumnsToShowContextValue = {
  columnsToShow: any[];
  showAll: (columns: any[]) => void;
  toggleToShow: (elements: any[], id: number) => void;
};

const initialCartContextValue: ColumnsToShowContextValue = {
  columnsToShow: [],
  showAll: () => {},
  toggleToShow: () => {},
};

export const ColumnsToShowContext =
  React.createContext<ColumnsToShowContextValue>(initialCartContextValue);

const ColumnsToShowProvider: React.FC = ({ children }) => {
  const [columnsToShow, setColumnsToShow] = React.useState<any[]>(() => {
    const storedColumnsToShow = sessionStorage.getItem('columnsToShow');
    return storedColumnsToShow ? JSON.parse(storedColumnsToShow) : [];
  });

  const showAll = (columns: any[]) => {
    // get only de ids
    const cleanColumns = columns.map((column) => {
      return {
        id: column.id,
      };
    });
    setColumnsToShow(cleanColumns);

    sessionStorage.setItem('columnsToShow', JSON.stringify(cleanColumns));
  };

  const toggleToShow = (elements: any[], id: number) => {
    const foundElement = elements.find((element) => element.id === id);
    const foundElementinContext = columnsToShow.find(
      (element) => element.id === id
    );
    if (foundElementinContext) {
      const updatedColumns = columnsToShow.filter(
        (element) => element.id !== id
      );

      const cleanColumns = updatedColumns.map((column) => {
        return {
          id: column.id,
        };
      });
      setColumnsToShow(cleanColumns);

      sessionStorage.setItem('columnsToShow', JSON.stringify(cleanColumns));
    } else {
      const updatedColumns = [...columnsToShow, foundElement];
      const cleanColumns = updatedColumns.map((column) => {
        return {
          id: column.id,
        };
      });
      setColumnsToShow(cleanColumns);

      sessionStorage.setItem('columnsToShow', JSON.stringify(cleanColumns));
    }
  };

  const columnsToShowContextValue: ColumnsToShowContextValue = React.useMemo(
    () => ({
      columnsToShow,
      showAll,
      toggleToShow,
    }),
    [columnsToShow]
  );

  return (
    <ColumnsToShowContext.Provider value={columnsToShowContextValue}>
      {children}
    </ColumnsToShowContext.Provider>
  );
};

export default ColumnsToShowProvider;
