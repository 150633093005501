import { getType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { MedicalReportState } from './medical-report.types';
import {
  MedicalReportsActions,
  updateMedicalReportData,
  createSignedPdf,
  setMedicalReportSections,
  setMedicalReportOptions,
  setMedicalReportPDF
} from './medical-report.actions';

const INITIAL_STATE: MedicalReportState = {
  signedPDF: '',
  data: {},
  options: [],
  sections: [],
  pdf: ''
};

const medicalReportsReducer: Reducer<
  MedicalReportState,
  MedicalReportsActions
> = (state = INITIAL_STATE, action: MedicalReportsActions) => {
  switch (action.type) {
    // Save new pdf file created by combining original pdf with a signature
    case getType(createSignedPdf):
      return { ...state, signedPDF: action.payload };
    // Update the data that the pdf will be filled with
    case getType(updateMedicalReportData):
      return { ...state, data: action.payload };
    case getType(setMedicalReportPDF):
      return { ...state, pdf: action.payload };
    case getType(setMedicalReportSections):
      return { ...state, sections: action.payload };
    case getType(setMedicalReportOptions):
      return { ...state, options: action.payload };
    default:
      return state;
  }
};

export default medicalReportsReducer;
