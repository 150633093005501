import React from 'react';
import logo from '../../assets/images/vitau-logo-light-bg.svg';
import './SplashScreen.scss';

const SplashScreen: React.FC = () => (
  <div className="splash-screen">
    <img src={logo} alt="vitau-logo-light-bg" className="splash-screen-logo" />
  </div>
);

export default SplashScreen;
