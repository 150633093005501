import React, { useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import useToast from 'hooks/useToast';
import { User } from 'types/user.types';
import { isPanelUser, getFirstRole } from 'utils/checkRole';
import constructName from 'utils/constructName';
import * as Sentry from '@sentry/react';
import Tracker from '@openreplay/tracker';
import { identify, group } from 'analytics';
import { bodyRegister } from 'components/authenticate/components/register/registerForm/register.types';
import authClient from '../api/AuthClient';

const { REACT_APP_ENV = 'development', REACT_APP_OPENREPLAY_KEY } = process.env;

/**
 * idle - unknown/pending auth status
 * authenticated - successfully authenticated
 * unauthenticated - unsuccessfully authenticated or logged out
 */
type AuthenticationStates = 'idle' | 'authenticated' | 'unauthenticated';

type IAuthState = {
  loading: boolean;
  authentication: AuthenticationStates;
  user: User | undefined;
};

type Login = (t: { email: string; password: string }) => Promise<void>;
type Logout = () => void;
type Register = (t: bodyRegister) => Promise<User | undefined>;

type IAuthContext = [
  IAuthState,
  {
    login: Login;
    logout: Logout;
    register: Register;
  }
];

type Props = {
  children: React.ReactNode;
};

const initialAuthState: IAuthState = {
  loading: true,
  authentication: 'idle',
  user: undefined,
};
const initialContext: IAuthContext = [
  initialAuthState,
  {
    login: async () => undefined,
    logout: () => undefined,
    register: async () => undefined,
  },
];

const AuthContext = React.createContext<IAuthContext>(initialContext);

const AuthProvider: React.FC<Props> = (props) => {
  const [state, setState] = useState<IAuthState>(initialAuthState);
  const client = useApolloClient();
  const [openToast] = useToast();

  function daysToMilliseconds(days: number) {
    // 👇️        hour  min  sec  ms
    return days * 24 * 60 * 60 * 1000;
  }
  /**
   * Perform logout and clear apollo cache. 'client.resetStore' is not used
   * becasue we don't provide resources that can be used while not being
   * logged in
   */

  const logout: Logout = useCallback(async () => {
    authClient.logout();
    client.clearStore();
    setState({
      loading: false,
      authentication: 'unauthenticated',
      user: undefined,
    });
    localStorage.removeItem('logoutDate');
  }, [client]);

  /**
   * Run on mount and check if there is a logged in user
   */
  useEffect(() => {
    const fetchUser = async () => {
      setState((prevState) => ({
        ...prevState,
        authentication: 'idle',
        loading: true,
      }));

      const isAuthenticated = await authClient.checkAuth();
      if (isAuthenticated) {
        const user = await authClient.whoAmI();

        /**
         * Setting user for third party tracking providers
         */

        if (REACT_APP_ENV !== 'local' && REACT_APP_ENV !== 'development') {
          // Implemented sentry user

          Sentry.setUser({
            id: String(user.id),
            email: user.email,
            name: constructName(user),
            user_type: getFirstRole(user?.groups ?? []),
          });

          // Implemented new version of openreplay and set email as user id

          const tracker = new Tracker({
            projectKey: REACT_APP_OPENREPLAY_KEY ?? '',
          });
          tracker.setUserID(user?.email);
          tracker.start({
            userID: user?.email,
            metadata: { env: REACT_APP_ENV },
            forceNew: true,
          });

          // Implemented analytics identify for segment

          identify(String(user?.id), {
            name: constructName(user),
            email: user?.email,
            user_type: getFirstRole(user?.groups ?? []),
          });

          group(getFirstRole(user?.groups ?? []));
        }

        setState({
          loading: false,
          authentication: 'authenticated',
          user,
        });
      } else {
        setState({
          loading: false,
          authentication: 'unauthenticated',
          user: undefined,
        });
      }
    };

    fetchUser();
  }, [client]);

  /**
   * Log in user with provided credentials. If the user that tried to
   * log in doesn't have the required permissions (Panel user), performs
   * a logout and displays a corresponding message
   */
  const login: Login = useCallback(
    async (credentials) => {
      try {
        const user = await authClient.login(credentials);

        // add sesion timer for close session after x time
        const sessionTime = daysToMilliseconds(13);
        const loginDate = new Date();
        const logoutDate = new Date(loginDate.getTime() + sessionTime);

        localStorage.setItem('logoutDate', JSON.stringify(logoutDate));
        if (isPanelUser(user.groups)) {
          setState({
            user,
            loading: false,
            authentication: 'authenticated',
          });
        } else {
          logout();
          openToast('No cuentas con acceso al panel.');
        }
      } catch (e: any) {
        let credentialError = null;

        if (
          e &&
          e.detail === 'No active account found with the given credentials'
        ) {
          credentialError = 'Correo electrónico o contraseña incorrecta';
        }
        logout();
        openToast(
          credentialError ??
          'Ocurrió un error al iniciar sesión, por favor inténtalo mas tarde.'
        );
      }
    },
    [logout, openToast]
  );

  const register: Register = async (data) => {
    return authClient.register(data);
  };

  const contextValue: IAuthContext = React.useMemo(
    () => [state, { login, logout, register }],
    [state, login, logout, register]
  );

  return <AuthContext.Provider value={contextValue} {...props} />;
};

export default AuthProvider;
export { AuthContext };
