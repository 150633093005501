import React, { useEffect, useState } from 'react';
import Backdrop from 'shared/backdrop';
import './alert.scss';

type AlertType = 'info' | 'success' | 'warning' | 'error';
export type AlertProps = {
  type?: AlertType;
  title?: string;
  text?: string;
  textButton?: string;
  icon?: string;
  className?: string;
  timeout?: number;
};

const TypesData: Record<AlertType, { title: string; icon: string }> = {
  info: {
    title: 'Información',
    icon: 'fas fa-info-circle',
  },
  success: {
    title: 'Éxito',
    icon: 'fas fa-check-circle',
  },
  warning: {
    title: 'Advertencia',
    icon: 'fas fa-exclamation-triangle',
  },
  error: {
    title: 'Error',
    icon: 'fas fa-times-circle',
  },
};

type AlertStates = 'hidden' | 'displaying';

const Alert: React.FC<AlertProps> = ({
  type = 'error',
  title = TypesData[type].title,
  text,
  icon = TypesData[type].icon,
  className = '',
}) => {
  const [state, setState] = useState<AlertStates>('hidden');

  useEffect(() => {
    setTimeout(() => {
      setState('displaying');
    }, 0);
  }, []);

  return (
    <Backdrop state={state}>
      <div className={`alert alert--${type} alert--${state} ${className}`}>
        <i className={icon} />
        <h1>{title}</h1>
        {text && <p>{text}</p>}
      </div>
    </Backdrop>
  );
};

export default Alert;
