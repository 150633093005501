/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/es';
import App from './App';
import 'tippy.js/dist/tippy.css';
import 'antd/dist/antd.min.css';
import './assets/styles/index.scss';

import Providers from './Providers';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

moment.locale('es');

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV !== 'local' && REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://39a21f74b7fb43de8917bd8195164592@o394750.ingest.sentry.io/5874486',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    environment: REACT_APP_ENV,
    tracesSampleRate: 0.5,

    /**
     * Ignred cause this thread https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
     */
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',

      /**
       * This errors are reported cause when there were bad connection in requests from api
       */
      'Failed to fetch',
      'NetworkError',
    ],
  });
}

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(
  <Providers>
    <App />
  </Providers>
);
