import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
} from '@apollo/client';
import { ServerError } from '@apollo/client/link/utils';
import * as Sentry from '@sentry/react';
import { RestLink } from 'apollo-link-rest';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import jsonToFormData from 'json-form-data';
import endpoint from '../config';
import authClient from './AuthClient';

/**
 * Used to typepatch paginated results as they come nested and
 * Apollo Link Rest can't automatically patch nested models
 * @param typename
 */
const paginatedTypePatcher =
  (typename: string) =>
  (data: any): any => {
    const transformedData = data;
    if (transformedData.results !== null) {
      transformedData.results = transformedData.results.map((result: any) => ({
        __typename: typename,
        ...result,
      }));
    }
    return transformedData;
  };

const cache = new InMemoryCache();

/**
 * Body Serializers
 */

/**
 * File encode serializer used when uploading
 * files or images
 * @param data
 * @param headers
 */
const fileEncode = (data: any, headers: Headers) => {
  const formData = jsonToFormData(data, {
    initialFormData: new FormData(),
  });
  if (headers.has('Content-type')) {
    headers.delete('Content-type');
  }
  headers.set('Accept', '*/*');
  return { body: formData, headers };
};

/**
 * Apollo rest link configuration
 */
// eslint-disable-next-line no-underscore-dangle
const _restLink = new RestLink({
  uri: endpoint.API_URL,
  credentials: 'include',
  headers: {
    'X-Api-Key': `${endpoint.API_TOKEN}`,
  },
  bodySerializers: {
    fileEncode,
  },
  /**
   * Each paginated query (for now) should have a type patcher
   */
  typePatcher: {
    ProductsPayload: paginatedTypePatcher('Product'),
    BaseProductPayload: paginatedTypePatcher('BaseProduct'),
    OrdersPayload: paginatedTypePatcher('Order'),
    batchesListExitsPayload: paginatedTypePatcher('Batch'),
    SubscriptionsOrdersPayload: paginatedTypePatcher('SubscriptionOrder'),
    CategoriesPayload: paginatedTypePatcher('Category'),
    DiseasesPayload: paginatedTypePatcher('Disease'),
    CompaniesPayload: paginatedTypePatcher('Company'),
    UsersPayload: paginatedTypePatcher('User'),
    CouponsPayload: paginatedTypePatcher('Coupon'),
    SubscriptionsPayload: paginatedTypePatcher('Subscription'),
    PatientsPayload: paginatedTypePatcher('Patient'),
    BatchesPayload: paginatedTypePatcher('Batch'),
    ProductInventoryPayload: paginatedTypePatcher('ProductInventory'),
    TagsPayload: paginatedTypePatcher('Tag'),
    VariantsPayload: paginatedTypePatcher('Variant'),
    SuppliersPayload: paginatedTypePatcher('Supplier'),
    BatchesProductPayload: paginatedTypePatcher('Batch'),
    InstitutionsProductPayload: paginatedTypePatcher(' Institution'),
  },
});

const restLink = _restLink as unknown as ApolloLink;

/**
 * Whenever we receive a 401 status from the API, execute a logout
 * and clear the apollo cache by reloading the window
 */

const errorLink = onError(({ networkError }) => {
  const errors = networkError as ServerError;
  if (networkError && 'statusCode' in networkError) {
    /**
     *  When the api code is 401 the sentry log will be avoided
     *  because it only indicates an expired token
     */
    if (networkError.statusCode === 401) {
      authClient.logout();
      window.location.reload();
      return;
    }

    if (networkError.statusCode === 403) {
      authClient.refreshCsrfToken().then(() => {
        window.location.reload();
      });
      return;
    }
  }

  Sentry.captureException(networkError, (scope) => {
    scope.setExtras({
      statusCode: errors?.statusCode,
      result: JSON.stringify(errors?.result),
    });
    return scope;
  });
});

/**
 * Add csrfToken to the headers of every request
 */
const csrfLink = setContext(async (_, { headers }) => {
  const csrfToken = await authClient.getCsrfToken();

  return {
    headers: {
      ...headers,
      [authClient.constants.CSRF_TOKEN_HEADER_KEY]: csrfToken,
    },
  };
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: ApolloLink.from([csrfLink, errorLink, restLink]),
  cache,
});

export default client;
