import { ActionType, createStandardAction } from 'typesafe-actions';
import {
  MedicalReportData,
  MedicalReportSections,
  ReportOptionsArray
} from './medical-report.types';

export const createSignedPdf = createStandardAction(
  'medicalReport/CREATE_SIGNED_PDF'
)<string>();

export const updateMedicalReportData = createStandardAction(
  'medicalReport/UPDATE_DATA'
)<MedicalReportData>();

export const setMedicalReportPDF = createStandardAction(
  'medicalReport/SET_PDF'
)<string>();

export const setMedicalReportSections = createStandardAction(
  'medicalReport/SET_SECTIONS'
)<MedicalReportSections[]>();
export const setMedicalReportOptions = createStandardAction(
  'medicalReport/SET_OPTIONS'
)<ReportOptionsArray[]>();

export type MedicalReportsActions = ActionType<
  | typeof createSignedPdf
  | typeof updateMedicalReportData
  | typeof setMedicalReportPDF
  | typeof setMedicalReportSections
  | typeof setMedicalReportOptions
>;
