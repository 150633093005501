import React, { useEffect, useState } from 'react';
import './Toast.scss';

type ToastType = 'info' | 'success' | 'warning' | 'error';
export type ToastProps = {
  type?: ToastType;
  text?: string;
  textButton?: string;
  icon?: string;
  timeout?: number;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDismiss?: () => void;
};

const TypesData: Record<
  ToastType,
  { text: string; textButton: string; icon: string }
> = {
  info: {
    text: 'Información',
    textButton: 'Ver más',
    icon: 'fas fa-info-circle',
  },
  success: {
    text: 'Éxito',
    textButton: 'Deshacer',
    icon: 'fas fa-check-circle',
  },
  warning: {
    text: 'Advertencia',
    textButton: 'Acción',
    icon: 'fas fa-exclamation-triangle',
  },
  error: {
    text: 'Error',
    textButton: 'Reintentar',
    icon: 'fas fa-times-circle',
  },
};

type ToastStates = 'hidden' | 'displaying';

const Toast: React.FC<ToastProps> = (props) => {
  const {
    onClick,
    onDismiss,
    type = 'error',
    timeout = 5000,
    text = TypesData[type].text,
    textButton = TypesData[type].textButton,
    icon = TypesData[type].icon,
    className = '',
  } = props;

  const [state, setState] = useState<ToastStates>('hidden');

  useEffect(() => {
    setState('displaying');

    setTimeout(() => {
      setState('hidden');
      setTimeout(() => {
        if (onDismiss) {
          onDismiss();
        }
      }, 200);
    }, timeout);
  }, [onDismiss, timeout]);

  return (
    <div className={`Toast Toast--${type} Toast--${state} ${className}`}>
      <div>
        <i className={icon} />
        <p>{text}</p>
      </div>
      {onClick && (
        <button type="button" onClick={onClick}>
          {textButton}
        </button>
      )}
    </div>
  );
};

export default Toast;
