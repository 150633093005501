export const roundPrice = (price: number) => {
  return Math.round((price + Number.EPSILON) * 100) / 100;
};

export const isDecimal = (num: number) => {
  return num - Math.floor(num) !== 0;
};

export const roundDecimals = (num: number) => {
  // check if number is decimal and if it has more than 2 decimals, round it to 2
  if (num.toString()?.split('.')[1]?.length > 2) {
    return `${num.toString().split('.')[0]}.${num
      .toString()
      .split('.')[1]
      .slice(0, 2)}
      `;
  }
  return num;
};
