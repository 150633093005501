import React from 'react';
import './backdrop.scss';

type BackdropState = 'hidden' | 'displaying';

type Props = {
  state: BackdropState;
  className?: string;
};

const Backdrop: React.FC<Props> = ({ state, className, children }) => {
  return (
    <div className={`backdrop backdrop--${state} ${className}`}>{children}</div>
  );
};

export default Backdrop;
