import React, { createContext, useCallback, useState, useEffect } from 'react';
import Alert, { AlertProps } from 'shared/alert';

type AlertOptions = Omit<AlertProps, 'title'>;
export type AlertOpen = (title: string, options?: AlertOptions) => void;
type AlertContext = Readonly<[AlertOpen]>;

const initialContext: AlertContext = [() => undefined];
const AlertContext = createContext<AlertContext>(initialContext);

const AlertProvider: React.FC = (props) => {
  const [state, setState] = useState<AlertProps & { isOpen: boolean }>({
    isOpen: false,
    title: '',
  });
  const openAlert = useCallback((title: string, options: AlertOptions = {}) => {
    setState({
      isOpen: true,
      title,
      ...options,
    });
  }, []);
  const contextValue = [openAlert] as const;

  const { isOpen, timeout, ...alertProps } = state;

  const { children } = props;

  useEffect(() => {
    /**
     * If the alert its open, autoclose after 2 seconds
     */
    if (isOpen) {
      setTimeout(() => {
        setState({ isOpen: false, title: '' });
      }, timeout ?? 2000);
    }
  }, [isOpen]);

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      {isOpen && <Alert {...alertProps} />}
    </AlertContext.Provider>
  );
};

export { AlertContext };
export default AlertProvider;
