import { useQuery } from '@apollo/client';
import { useToast } from 'hooks';
import { Store } from 'types/stores.types';
import { STORES_GET } from './stores.queries';

const useStores = ({
  onComplete,
}: { onComplete?: (data: any) => void } = {}) => {
  const [openToast] = useToast();
  return useQuery<{ stores: Store[] }>(STORES_GET, {
    fetchPolicy: 'no-cache',
    onError: () => openToast('Error al cargar las tiendas.', { type: 'error' }),
    onCompleted: (data) => onComplete?.(data),
  });
};

export default useStores;
