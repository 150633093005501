/* eslint-disable no-debugger */
import React, { useState } from 'react';
import { Order } from 'types/orders.types';
import { SubscriptionOrder } from 'types/subscriptions.types';

type BulkOrderStatusContextValue = {
  bulkOrders: (Order | SubscriptionOrder)[];
  setBulkOrders: React.Dispatch<React.SetStateAction<(Order | SubscriptionOrder)[]>>;
};

const initialBulkOrderStatusValue: BulkOrderStatusContextValue = {
  bulkOrders: [],
  setBulkOrders: () => { },
};

export const BulkOrderStatusContext =
  React.createContext<BulkOrderStatusContextValue>(initialBulkOrderStatusValue);

const BulkOrderStatusProvider: React.FC = ({ children }) => {
  const [bulkOrders, setBulkOrders] = useState<(Order | SubscriptionOrder)[]>([])

  const BulkOrderStatusContextValue: BulkOrderStatusContextValue = React.useMemo(
    () => ({
      bulkOrders,
      setBulkOrders,
    }),
    [bulkOrders]
  );

  return (
    <BulkOrderStatusContext.Provider value={BulkOrderStatusContextValue}>
      {children}
    </BulkOrderStatusContext.Provider>
  );
};

export default BulkOrderStatusProvider;
