import React, { createContext, useState, useCallback, useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { isDoctor } from 'utils/checkRole';

type ISidebarState = {
  hidden: boolean;
  collapsed: boolean;
};

type Hidden = (hidden: boolean) => void;
type Collapsed = (collapsed: boolean) => void;

type ISidebarContext = [
  ISidebarState,
  {
    setHidden: Hidden;
    setCollapsed: Collapsed;
  }
];

const initialSidebarState: ISidebarState = { hidden: true, collapsed: false };

const initialContext: ISidebarContext = [
  initialSidebarState,
  {
    setHidden: () => undefined,
    setCollapsed: () => undefined,
  },
];

const PanelSidebarContext = createContext<ISidebarContext>(initialContext);

const PanelSidebarProvider: React.FC = (props) => {
  const [{ user }] = useAuth();
  const isUserDoctor = isDoctor(user?.groups ?? []);

  const [state, setState] = useState<ISidebarState>(initialSidebarState);

  const setHidden: Hidden = useCallback(
    (hidden: boolean) =>
      setState((prevState) => ({
        ...prevState,
        hidden,
      })),
    []
  );

  const setCollapsed: Collapsed = useCallback(
    (collapsed: boolean) =>
      setState((prevState) => ({
        ...prevState,
        collapsed,
      })),
    []
  );

  const { children } = props;

  const contextValue: ISidebarContext = React.useMemo(
    () => [state, { setHidden, setCollapsed }],
    [state, setHidden, setCollapsed]
  );

  /**
   * Set open if user is doctor
   */

  useEffect(() => {
    if (user) {
      setState((prevState) => ({
        ...prevState,
        collapsed: !isUserDoctor,
      }));
    }
  }, [user]);

  return (
    <PanelSidebarContext.Provider value={contextValue}>
      {children}
    </PanelSidebarContext.Provider>
  );
};

export { PanelSidebarContext };
export default PanelSidebarProvider;
