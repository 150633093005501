import React, { createContext, useState, useCallback } from 'react';

type IPanelHeaderState = {
  title: string;
};

type Title = (title: string) => void;

type IPanelHeaderContext = [
  IPanelHeaderState,
  {
    setTitle: Title;
  }
];

const initialPanelHeaderState: IPanelHeaderState = {
  title: '',
};

const initialContext: IPanelHeaderContext = [
  initialPanelHeaderState,
  {
    setTitle: () => undefined,
  },
];

const PanelHeaderContext = createContext<IPanelHeaderContext>(initialContext);

const PanelHeaderProvider: React.FC = (props) => {
  const [state, setState] = useState<IPanelHeaderState>(
    initialPanelHeaderState
  );
  const setTitle: Title = useCallback(
    (title: string) =>
      setState((prevState) => ({
        ...prevState,
        title,
      })),
    []
  );

  const { children } = props;

  const contextValue: IPanelHeaderContext = React.useMemo(
    () => [state, { setTitle }],
    [state, setTitle]
  );

  return (
    <PanelHeaderContext.Provider value={contextValue}>
      {children}
    </PanelHeaderContext.Provider>
  );
};

export { PanelHeaderContext };
export default PanelHeaderProvider;
